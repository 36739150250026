import {companyPermissions} from "@/mixins/companyPermissions";
import SmsService from "@/services/sms.template.service";

const service = new SmsService();

export default {
  name: "SmsCount",

  props: {
    company: {type: String, default: null},
    agent: {type: String, default: null},
    dateRange: {type: String, default: 'ALL_TIME'},
  },

  data() {
    return {
      sent: 0,
      received: 0,
      isLoading: true,
    }
  },

  computed: {
    total() {
      return this.sent + this.received;
    }
  },

  async mounted() {
    await this.count();
  },

  methods: {
    async count() {
      const res = await service.count(this.dateRange);
      if (res && !res.error) {
        this.isLoading = false;
        this.sent = res.data.sent;
        this.received = res.data.received;
      }
    },
  },

  watch: {
    dateRange: {
      async handler() {
        await this.count();
      }
    }
  },

  mixins: [companyPermissions]
};