import {bus} from '@/helpers/bus';

import AudioPlayer from "@/components/AudioPlayer/index.vue";
import DateHelper from '@/helpers/date.helper';

import CallService from '@/services/call.service';

const callService = new CallService();

export default {
    name: 'calls',

    components: {AudioPlayer},

    data() {
        return {
            emptyCalls: false,
            callStatus: 'completed',
            callStatuses: ['completed', 'no-answer', 'busy'],
            calls: [],
            perPage: 25,
            totalRows: 0,
            currentPage: 1,
        }
    },

    async mounted() {
        this.tabComponent = 'calls';
        this.list();
    },

    methods: {
        getStatus(status, way) {
            let fixStatus = '';

            switch (status) {
                case "no-answer":
                    fixStatus = "No Answer";
                    break;
                case "failed":
                    fixStatus = "No Answer";
                    break;
                case "busy":
                    fixStatus = "No Answer";
                    break;
                case "cancel":
                    fixStatus = "No Answer";
                    break;
                case "completed":
                    fixStatus = way === 'in' ? "Inbound Call" : "Outbound Call";
                    break;
            }

            return fixStatus;
        },

        getInitials(customer) {
            if (!customer) return 'U';
            return customer.FirstName ? customer.FirstName[0] : '' + customer.LastName ? customer.LastName[0] : '';
        },

        setPerPage(value) {
            this.perPage = +(value);
            bus.$emit('HIDE_ALL_MODALS');
            this.list();
        },

        setCallStatus(value) {
            this.callStatus = value;
            bus.$emit('HIDE_ALL_MODALS');
            this.list();
        },

        async list(page) {
            this.currentPage = page || 1;
            let skip = this.perPage * (this.currentPage - 1);

            const res = await callService.list(this.perPage, skip, {callStatus: this.callStatus});
            if (res && !res.error) {
                this.calls = res.data.calls;
                this.totalRows = res.data.count;
                this.emptyCalls = this.calls.length <= 0;
            }
        },

        async next() {
            await this.list(this.currentPage + 1);
        },

        async prev() {
            await this.list(this.currentPage - 1);
        },

        getRecordingUrl(call) {
            return this.$calls_server() + '/api/call/recoding/' + call.CallRecording?._id + '?token=' + localStorage.getItem('token');
        },

        prettyDate(date) {
            return DateHelper.entryDate(date);
        },

        niceTime(callTime) {
            const hours = Math.floor(callTime / (60 * 60));
            const minutes = Math.floor((callTime - (hours * 60 * 60)) / 60);
            const seconds = callTime - hours * 60 * 60 - minutes * 60;
            return (hours ? hours + "h " : "") + (minutes ? minutes + "m " : "") + (seconds ? seconds + "s " : "0 seconds");
        }
    }
}