import {groupBy} from "lodash";

import Dh from "@/helpers/date.helper";
import Utils from "@/helpers/utils";

import CallService from '@/services/call.service';

const callService = new CallService();

export default {
    name: 'live',

    data() {
        return {
            isLive: false,

            calls: [],
            incomingCalls: [],
            outgoingCalls: [],
            callCountUp: [],

            series1: [],
            chartOptions1: {
                chart: {
                    type: 'bar',
                    toolbar: {
                        show: false,
                        tools: {
                            download: false,
                            selection: false,
                            zoom: false,
                            zoomin: false,
                            zoomout: false,
                            pan: false,
                            reset: false
                        },
                    },
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '65%',
                        endingShape: 'rounded'
                    },
                },
                dataLabels: {
                    enabled: true,
                    style: {
                        fontSize: '.9rem',
                        fontWeight: 'bold'
                    },
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: this.last10min(),
                    labels: {
                        show: true
                    }
                },
                yaxis: {
                    labels: {
                        formatter: val => val ? val.toFixed(0) : 0
                    }
                },
                grid: {
                    show: false,
                },
                colors: ["#ff5108", "#221ECD", '#FF0000', "#FFAA00", "#30B565"],
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val
                        }
                    }
                },
            },

            series2: [],
            chartOptions2: {
                chart: {
                    type: 'bar',
                    toolbar: {
                        show: false,
                        tools: {
                            download: false,
                            selection: false,
                            zoom: false,
                            zoomin: false,
                            zoomout: false,
                            pan: false,
                            reset: false
                        },
                    },
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '75%',
                        endingShape: 'rounded'
                    },
                },
                dataLabels: {
                    enabled: true,
                    style: {
                        fontSize: '.9rem',
                        fontWeight: 'bold'
                    },
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: this.last10h(),
                    labels: {
                        show: true
                    }
                },
                yaxis: {
                    labels: {
                        formatter: val => val ? val.toFixed(0) : 0
                    }
                },
                grid: {
                    show: false,
                },
                colors: ["#ff5108", "#221ECD", '#FF0000', "#FFAA00", "#30B565"],
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val
                        }
                    }
                },
            },

            perPage: 2000,
            totalRows: 0,
            currentPage: 1,
        }
    },

    sockets: {
        CALL_STATUS(call) {
            if (this.isLive) {
                this.addCall(call);
                this.clearCallOnEnd(call);
                this.setChartSeries();
            }
        }
    },

    async mounted() {
        if (localStorage.getItem('call_count_up')) {
            this.callCountUp = JSON.parse(localStorage.getItem('call_count_up'));
        }
        await this.list();
    },

    computed: {
        callsByHours() {
            const monthName = item => Dh.customFormat(item.createdAt, 'h A');
            return groupBy(this.calls, monthName);
        },

        callsByMinutes() {
            const monthName = item => Dh.customFormat(item.createdAt, 'h:mm A');
            return groupBy(this.calls, monthName);
        }
    },

    methods: {
        async toggleLive() {
            this.isLive = !this.isLive;
            await this.list();
        },

        async list(page) {
            this.currentPage = page || 1;
            let skip = this.perPage * (this.currentPage - 1);

            this.incomingCalls = [];
            this.outgoingCalls = [];
            this.series1 = [];
            this.series2 = [];

            const query = {
                callStatus: ['ringing', 'in-progress', 'no-answer', 'busy', 'completed'],
                startDate: Dh.startOf(new Date(), 'day').toDate(),
                endDate: Dh.endOf(new Date(), 'day').toDate(),
                Live: this.isLive
            }

            const res = await callService.list(this.perPage, skip, query, '_id');
            if (res && !res.error) {
                this.calls = res.data.calls;

                this.setChartSeries();

                this.calls.forEach(call => {
                    if (call.Way === 'in') this.addIncomingCall(call);
                    else if (call.Way === 'out') this.addOutgoingCall(call);
                });
            }
        },

        addIncomingCall(call) {
            const index = this.incomingCalls.findIndex(el => el.CallSid === call.CallSid);
            if (index > -1) {
                this.incomingCalls[index].CallStatus = call.CallStatus;
                this.incomingCalls[index].Live = call.Live;
                this.incomingCalls[index].Agent = call.Agent;
                this.incomingCalls[index].CallDuration = call.CallDuration;
                this.increaseCallTime(call);
            } else {
                this.incomingCalls.unshift(call);
            }
        },

        addOutgoingCall(call) {
            const index = this.outgoingCalls.findIndex(el => el.CallSid === call.CallSid);
            if (index > -1) {
                this.outgoingCalls[index].CallStatus = call.CallStatus;
                this.outgoingCalls[index].Live = call.Live;
                this.outgoingCalls[index].Agent = call.Agent;
                this.outgoingCalls[index].CallDuration = call.CallDuration;
                this.increaseCallTime(call);
            } else {
                this.outgoingCalls.unshift(call);
            }
        },

        addCall(call) {
            const index = this.calls.findIndex(el => el.CallSid === call.CallSid);
            if (index > -1) {
                this.calls[index].CallStatus = call.CallStatus;
                this.calls[index].Live = call.Live;
                this.calls[index].Agent = call.Agent;
                this.calls[index].CallDuration = call.CallDuration;
            } else {
                this.calls.unshift(call);
            }

            if (call.Way === 'in') this.addIncomingCall(call);
            else if (call.Way === 'out') this.addOutgoingCall(call);

            if (call.Live) {
                this.addCallCountUp(call);
            }
        },

        removeCall(call) {
            const callIndex = this.calls.findIndex(el => el.CallSid === call.CallSid);
            if (callIndex > -1) {
                this.calls.splice(callIndex, 1);
            }

            if (call.Way === 'in') {
                const index = this.incomingCalls.findIndex(el => el.CallSid === call.CallSid);
                if (index > -1) {
                    this.incomingCalls.splice(index, 1);
                }
            } else if (call.Way === 'out') {
                const index = this.outgoingCalls.findIndex(el => el.CallSid === call.CallSid);
                if (index > -1) {
                    this.outgoingCalls.splice(index, 1);
                }
            }
        },

        setChartSeries() {
            const series = [
                {
                    name: this.$t('ringing'),
                    data: []
                }, {
                    name: this.$t('in-progress'),
                    data: []
                }, {
                    name: this.$t('no-answer'),
                    data: []
                }, {
                    name: this.$t('busy'),
                    data: []
                }, {
                    name: this.$t('completed'),
                    data: []
                }
            ];

            if (this.isLive) {
                this.chartOptions1.xaxis.categories = this.last10min();
                this.series1 = series;

                this.chartOptions1.xaxis.categories.forEach((minute, index) => {
                    series.forEach(el => el.data.push(0));

                    if (this.callsByMinutes[minute]) {

                        this.callsByMinutes[minute].forEach(call => {
                            if (call.CallStatus === 'ringing') series[0].data[index]++
                            else if (call.CallStatus === 'in-progress') series[1].data[index]++
                            else if (call.CallStatus === 'no-answer') series[2].data[index]++
                            else if (call.CallStatus === 'busy') series[3].data[index]++
                            else if (call.CallStatus === 'completed') series[4].data[index]++
                        });
                    }
                })

            } else {
                this.chartOptions2.xaxis.categories = this.last10h();
                this.series2 = series;

                this.chartOptions2.xaxis.categories.forEach(hour => {
                    series.forEach(el => el.data.push(0));

                    const index = series[0].data.length - 1;

                    if (this.callsByHours[hour]) {

                        this.callsByHours[hour].forEach(call => {
                            if (call.CallStatus === 'ringing') series[0].data[index]++
                            else if (call.CallStatus === 'in-progress') series[1].data[index]++
                            else if (call.CallStatus === 'no-answer') series[2].data[index]++
                            else if (call.CallStatus === 'busy') series[3].data[index]++
                            else if (call.CallStatus === 'completed') series[4].data[index]++
                        });
                    }
                })
            }
        },

        addCallCountUp(call) {
            const index = this.callCountUp.findIndex(el => el.CallSid === call.CallSid);
            if (index === -1) {
                this.callCountUp.push({CallSid: call.CallSid, CallDuration: 0});
                localStorage.setItem('call_count_up', JSON.stringify(this.callCountUp));
            }
        },

        removeCallCountUp(call) {
            const index = this.callCountUp.findIndex(el => el.CallSid === call.CallSid);
            if (index > -1) {
                this.callCountUp.splice(index, 1);
                if (this.callCountUp.length) {
                    localStorage.setItem('call_count_up', JSON.stringify(this.callCountUp));
                } else {
                    localStorage.removeItem('call_count_up');
                }
            }
        },

        getCallCountUp(call) {
            const index = this.callCountUp.findIndex(el => el.CallSid === call.CallSid);
            if (index > -1) {
                return this.callCountUp[index];
            }
            return {
                CallDuration: 0
            };
        },

        increaseCallTime(call) {
            const $this = this;

            const increaseTime = call => {
                setTimeout(() => {
                    if (call.CallStatus === 'completed') {
                        $this.removeCallCountUp(call);
                    }

                    const callCountUp = $this.getCallCountUp(call);
                    if (callCountUp) {
                        callCountUp.CallDuration++;
                        localStorage.setItem('call_count_up', JSON.stringify($this.callCountUp));
                    }

                    if (call.CallStatus === 'in-progress') {
                        increaseTime(call);
                    }
                }, 1000);
            };

            if (call.CallStatus === 'in-progress' || call.CallStatus === 'completed')
                increaseTime(call);
        },

        clearCallOnEnd(call) {
            if (this.isLive && (
                call.CallStatus === 'busy' || call.CallStatus === 'completed' || call.CallStatus === 'no-answer'
            )) {
                this.removeCall(call);
                this.removeCallCountUp(call);
            }
        },

        last10h() {
            let labels = [];

            for (let i = 9; i >= 0; i--) {
                labels.push(Dh.subtract(new Date(), i, 'hours').format('h A'));
            }

            return labels;
        },

        last10min() {
            let labels = [];

            for (let i = 9; i >= 0; i--) {
                labels.push(Dh.subtract(new Date(), i, 'minutes').format('h:mm A'));
            }

            return labels;
        },
    },

    filters: {
        callDate(date) {
            return Dh.customFormat(date, 'h:mm A');
        },

        customerName(name) {
            if (name) {
                return `${name.FirstName} ${name.LastName || ''}`;
            }
            return '-';
        },

        outgoingAgentName(name) {
            if (name) {
                return `${name.FirstName} ${name.LastName}`;
            }
            return '-';
        },

        incomingAgentName({Agent, CallStatus}) {
            if (CallStatus === 'ringing') {
                return '...';
            }
            if (CallStatus === 'no-answer' || CallStatus === 'busy') {
                return '-';
            }
            if (Agent) {
                return `${Agent.FirstName} ${Agent.LastName}`;
            }
            return '-';
        },

        callDuration(seconds) {
            if (seconds) {
                return Dh.secondsToTime(seconds);
            }
            return '0'
        },

        formatUsPhone(phone) {
            if (Utils.isUsPhone(phone)) {
                return Utils.formatUSNumber(phone);
            }
            return phone;
        }
    },
}
