import { render, staticRenderFns } from "./Calls.html?vue&type=template&id=b0fbdc6e&scoped=true&external"
import script from "./Calls.js?vue&type=script&lang=js&external"
export * from "./Calls.js?vue&type=script&lang=js&external"
import style0 from "./Calls.scss?vue&type=style&index=0&id=b0fbdc6e&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b0fbdc6e",
  null
  
)

/* custom blocks */
import block0 from "./locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fviews%2FCallCenter%2FCalls%2Findex.vue&scoped=true&external"
if (typeof block0 === 'function') block0(component)

export default component.exports