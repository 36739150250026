import {companyPermissions} from "@/mixins/companyPermissions";
import CallService from "@/services/call.service";

const service = new CallService();

export default {
  name: "CallsCount",

  props: {
    company: {type: String, default: null},
    agent: {type: String, default: null},
    dateRange: {type: String, default: 'ALL_TIME'},
  },

  data() {
    return {
      inbound: 0,
      outbound: 0,
      missed: 0,
      transfer: 0,
      busy: 0,
      isLoading: true,
    }
  },

  computed: {
    total() {
      return this.inbound + this.outbound;
    }
  },

  async mounted() {
    await this.count();
  },

  methods: {
    async count() {
      const res = await service.count(this.dateRange, this.agent);
      if (res && !res.error) {
        this.isLoading = false;
        this.inbound = res.data.inbound;
        this.outbound = res.data.outbound;
        this.missed = res.data.missed;
        this.transfer = res.data.transferred;
        this.busy = res.data.busy;
      }
    },
  },

  watch: {
    dateRange: {
      async handler() {
        await this.count();
      }
    },
    agent: {
      async handler() {
        await this.count();
      }
    }
  },

  mixins: [companyPermissions],
};