import {bus} from "@/helpers/bus";

import CallsCount from "@/components/Dashboards/CallsCount/index";
import SmsCount from "@/components/Dashboards/SmsCount/index";
import GlobalAreaChart from "@/components/Dashboards/GlobalAreaChart/index";

export default {

  name: "CallsDashboard",

  components: {
    CallsCount,
    SmsCount,
    GlobalAreaChart
  },

  data() {
    return {
      dateRange: "TODAY",
      dateRanges: ["ALL_TIME", "TODAY", "MONTH_TO_DAY", "LAST_MONTH"],
    }
  },

  methods: {
    setDateRange(range) {
      bus.$emit("HIDE_ALL_MODALS");
      this.dateRange = range;
    }
  }
}
